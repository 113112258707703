/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState, useMemo } from "react";

import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Space,
  Table,
  notification,
  Form,
  Modal,
  Select,
  Upload,
} from "antd";
import { backend_api } from "../config";
import axios from "axios";
import { render } from "@testing-library/react";
import {
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import TextArea from "antd/lib/input/TextArea";
import Papa from "papaparse";
const Context = React.createContext({
  name: "Default",
});

const defaultKeysOption = [
  { value: "Name", label: "Name" },
  { value: "Title", label: "Title" },
  { value: "Company Name", label: "Company Name" },
  { value: "Phone Number", label: "Phone Number" },
  { value: "Email", label: "Email" },
  { value: "Company Linkedin Url", label: "Company Linkedin Url" },
  { value: "Person Linkedin Url", label: "Person Linkedin Url" },
  { value: "Company Address", label: "Company Address" },
  { value: "Modifier", label: "Modifier" },
];

function List() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const [listData, setListData] = useState([]);
  const [lawFirmDataLoading, setLawFirmDataLoading] = useState(false);
  const [searchLawFirm, setSearchLawFirm] = useState("");
  const [filteredListData, setFilteredListData] = useState([]);
  const [newLeadModalOpen, setNewListModalOpen] = useState(false);
  const [subscribersModalOpen, setSubscribersModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [law_firm_name, setLawFirmName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [personName, setPersonName] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [modifier, setModifier] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [personLinkedinUrl, setPersonLinkedinUrl] = useState("");
  const [companyLinkedinUrl, setCompanyLinkedinUrl] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [title, setTitle] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [deleteLeadModalOpen, setDeleteLeadModalOpen] = useState("");
  const [currentSelectedList, setCurrentSelectedList] = useState([]);
  const [scriptData, setScriptData] = useState([]);
  const [currentSelectedScript, setCurrentSelectedScript] = useState();
  const [csvData, setCsvData] = useState([]);
  const [keyMapList, setKeyMapList] = useState([]);
  const [listDataForDelete, setListDataForDelete] = useState([]);
  const [filteredLawFirmData, setFilteredLawFirmData] = useState([]);
  const [listTitle, setListTitle] = useState('')
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [timezoneList, setTimezoneList] = useState([]);

  useEffect(() => {
    if (user == null) {
      history.push("/");
      return;
    }
    setTimezoneList([
      { "value": "America/New_York", "label": "Eastern Time (ET)" },
      { "value": "America/Chicago", "label": "Central Time (CT)" },
      { "value": "America/Denver", "label": "Mountain Time (MT)" },
      { "value": "America/Los_Angeles", "label": "Pacific Time (PT)" },
      { "value": "America/Anchorage", "label": "Alaska Time (AKT)" },
      { "value": "Pacific/Honolulu", "label": "Hawaii-Aleutian Time (HAT)" }
    ])
    // axios.get('https://docs.google.com/spreadsheets/d/e/2PACX-1vT7Qn5nABYDjLb73ZyD3cgqA68rp-wsw601TmwQLtztyQGRcQV1bI6WBV2IA0m9Utns3GdBFLql1Rj2/pub?gid=0&single=true&output=csv').then((response) => {
    //   if(response.status == 200) {
    //     let parsingData= parseCSV(response.data)
    //     setListData(parsingData)
    //     setFilteredListData(parsingData)
    //   }
    // })

    // getLeads();
    getList();
    getTags();
  }, []);
  const getTags = () => {
    const auth = Buffer.from(`${process.env.REACT_APP_FLUENT_CRM_USERNAME}:${process.env.REACT_APP_FLUENT_CRM_PASSWORDD}`).toString('base64');

    axios.get(`${process.env.REACT_APP_FLUENT_CRM_API}/tags`, {
      headers: {
        Authorization: `Basic ${auth}`
      }
    }).then((response) => {
      setTagList([])
      if (response.status == 200) {
        response.data.tags.data.forEach(element => {
          tagList.push({
            label: element.title,
            value: element.id,
          });
        });
        setTagList(tagList)
      }
    })
  }
  const getList = () => {
    axios
      .get(`${backend_api}/list?user_id=${user.id}`)
      .then((response) => {
        if (response.status == 200) {
          console.log("Scripts", response.data);
          if (response.data.success) {
            
            setFilteredListData(response.data.message)
            setListData(response.data.message)
          } else
            api.info({
              message: `Server Error`,
              description: (
                <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
              ),
              placement: "topRight",
            });
        } else {
          api.info({
            message: `Server Error`,
            description: (
              <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
            ),
            placement: "topRight",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getLeads = () => {
    setLawFirmDataLoading(true);
    axios
      .get(`${backend_api}/lead?user=${user.id}`)
      .then((response) => {
        if (response.status == 200) {
          console.log(response.data);
          if (response.data.success) {
            setListData(response.data.message);
            setFilteredListData(response.data.message);
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({ message: "Error", description: "Server Error" });
        }
      })
      .catch((error) => {
        notification.error({ message: "Error", description: "Server Error" });
      })
      .finally(() => {
        setLawFirmDataLoading(false);
        setNewListModalOpen(false);
      });
  };
  const handleFileUpload = ({ file }) => {
    setSubscribersModalOpen(true);
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setCsvData(result.data);
          let keyMapListTemp = [];
          Object.keys(result.data[0]).map((originalKey) => {
            keyMapListTemp.push({ original: originalKey, alter: originalKey });
          });
          setKeyMapList(keyMapListTemp);
        },
        error: (error) => {
          notification.error({
            message: "Error",
            description: "Failed to upload",
          });
          console.error(error);
        },
      });
    };

    reader.onerror = () => {
      notification.error({
        message: "Error",
        description: "Failed to read file",
      });
    };

    reader.readAsText(file);
  };

  const handleSaveNewListCSVClick = () => {
    if(listTitle == '') {
      notification.info({
        message: "info",
        description: `Please insert title`,
      });
      return;
    }
    if(selectedTimezone == undefined) {
      notification.info({
        message: "info",
        description: `Please insert timezone`,
      });
      return;
    }
    setIsLoading(true);
    const tempCsvData = csvData;
    console.log({ tempCsvData });
    const newCsvData = tempCsvData.map((csvItem) => {
      keyMapList.map((keyMap) => {
        if (keyMap.alter !== keyMap.original) {
          csvItem[keyMap.alter] = csvItem[keyMap.original];
          delete csvItem[keyMap.original];
        }
      });
      return csvItem;
    });
    console.log({ newCsvData });
    axios
      .post(`${backend_api}/listcsv`, {
        title: listTitle,
        request: newCsvData,
        user_id: user.id,
        tags: selectedTags,
        timezone: selectedTimezone
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success) {
            notification.success({
              message: "Success",
              description: `Successfully Created total ${newCsvData.length} / ${response.data.message.success}`,
            });
            getList();
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({
            message: "Error",
            description: "Server Error",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Server Error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setSubscribersModalOpen(false);
        clearLeadForm();
      });
  };
  const handleKeyChange = (option, original) => {
    let keyMapIndex = keyMapList.findIndex(
      (keyMap) => keyMap.original == original
    );
    if (keyMapIndex == -1) {
      keyMapList.push({ original, alter: option });
    } else {
      keyMapList[keyMapIndex].alter = option;
    }
    setKeyMapList(keyMapList);
  };

  const parseCSV = (csvText) => {
    const rows = csvText.split(/\r?\n/); // Split CSV text into rows, handling '\r' characters
    const headers = rows[0]
      .replace(/\s+/g, "")
      .split(",")
      .filter((item) => item !== ""); // Extract headers (assumes the first row is the header row)
    const data = []; // Initialize an array to store parsed data
    for (let i = 1; i < rows.length; i++) {
      const modifiedText = rows[i].replace(/("[^"]*")|,/g, (match, group1) => {
        // If match is within double quotes, return it unchanged
        if (group1) {
          return group1;
        } else {
          // Replace comma outside of quotes with @@@
          return "@@@";
        }
      });

      // Replace remaining commas with @@@
      const rowData = modifiedText.split("@@@"); // Split the row, handling '\r' characters
      const rowObject = {};
      for (let j = 0; j < headers.length; j++) {
        rowObject[headers[j]] = rowData[j];
      }
      data.push(rowObject);
    }
    return data;
  };

  const clearLeadForm = () => {
    setListTitle('')
    setSelectedTags([])
    setSelectedTimezone()
  };

  const handleCancel = () => {
    setNewListModalOpen(false);
    setDeleteLeadModalOpen(false);
    setSubscribersModalOpen(false);
    clearLeadForm();
  };

  const handleSaveNewLead = () => {
    const request = {
      law_firm_name: law_firm_name,
      phone_number: phoneNumber,
      email: email,
      person_name: personName,
      person_title: title,
      site: siteUrl,
      company_address: companyAddress,
      person_linkedin_url: personLinkedinUrl,
      company_linkedin_url: companyLinkedinUrl,
      modifier: modifier,
      user_id: user.id,
      script_id: currentSelectedScript,
      tags: selectedTags
    };
    setIsLoading(true);
    if (modalTitle == "Add New Lead") {
      axios
        .post(`${backend_api}/lead`, request)
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);
            if (response.data.success) {
              notification.success({
                message: "Success",
                description: "Successfully Created",
              });
              getLeads();
            } else {
              notification.error({
                message: "Error",
                description: response.data.message,
              });
            }
          } else {
            notification.error({
              message: "Error",
              description: "Server Error",
            });
          }
        })
        .catch((error) => {
          notification.error({ message: "Error", description: "Server Error" });
        })
        .finally(() => {
          setIsLoading(false);
          setNewListModalOpen(false);
          clearLeadForm();
        });
    } else {
      console.log(currentSelectedList);
      axios
        .put(`${backend_api}/lead/${currentSelectedList.id}`, request)
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);
            if (response.data.success) {
              notification.success({
                message: "Success",
                description: "Successfully Updated",
              });
              getLeads();
            } else {
              notification.error({
                message: "Error",
                description: response.data.message,
              });
            }
          } else {
            notification.error({
              message: "Error",
              description: "Server Error",
            });
          }
        })
        .catch((error) => {
          notification.error({ message: "Error", description: "Server Error" });
        })
        .finally(() => {
          setIsLoading(false);
          setNewListModalOpen(false);
          clearLeadForm();
        });
    }
  };

  const handleSearchLawFirmChange = (e) => {
    setSearchLawFirm(e.target.value);
    const searchKey = e.target.value;
    filterDataAsync(listData, searchKey).then((filteredData) => {
      setFilteredListData(filteredData);
    });
  };

  const filterDataAsync = (data, key) => {
    return new Promise((resolve) => {
      const filteredData = data.filter(
        (item) =>
          item.name?.toLowerCase().includes(key.toLowerCase()) 
      );
      resolve(filteredData);
    });
  };

  const handleDetailButton = (list) => {
    setModalTitle("Detail List");
    setNewListModalOpen(true)
    axios
    .get(`${backend_api}/list_lead?list_id=${list.id}`)
    .then((response) => {
      if (response.status == 200) {
        console.log("Scripts", response.data);
        if (response.data.success) {
          
          setFilteredLawFirmData(response.data.message)
        } else
          api.info({
            message: `Server Error`,
            description: (
              <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
            ),
            placement: "topRight",
          });
      } else {
        api.info({
          message: `Server Error`,
          description: (
            <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
          ),
          placement: "topRight",
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const handleDeleteButton = (record) => {
    setDeleteLeadModalOpen(true);
    setCurrentSelectedList(record);
  };

  const handleDeleteList = () => {
    setIsLoading(true)
    axios
      .post(`${backend_api}/delete_list`, { listDataForDelete })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success) {
            notification.success({
              message: "Success",
              description: "Lead deleted",
            });
            getList();
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({ message: "Error", description: "Server Error" });
        }
      })
      .catch((error) => {
        notification.error({ message: "Error", description: "Server Error" });
      })
      .finally(() => {
        setIsLoading(false);
        setDeleteLeadModalOpen(false);
      });
  };
  const handleDeleteListItem = () => {

    axios
      .delete(`${backend_api}/list?id=${currentSelectedList.id}`)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success) {
            notification.success({
              message: "Success",
              description: "Lead deleted",
            });
            getList();
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({ message: "Error", description: "Server Error" });
        }
      })
      .catch((error) => {
        notification.error({ message: "Error", description: "Server Error" });
      })
      .finally(() => {
        setIsLoading(false);
        setDeleteLeadModalOpen(false);
      });
  };

  const handleScriptChange = (value) => {
    console.log(`selected ${value}`);
    setCurrentSelectedScript(value);
  };

  const list_columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
    },
    {
      title: "Lead Count",
      dataIndex: "lead_count",
      key: "lead_count",
    },
    {
      title: "Timezone",
      dataIndex: "timezone",
      key: "timezone",
    },
    {
      title: "View",
      render(_, record) {
        return (
          <div>
            <Button type="link" onClick={(e) => handleDetailButton(record)}>
              Detail
            </Button>
          </div>
        );
      },
      width: '200px'
    },
    {
      title: "Delete",
      render(_, record) {
        return (
          <div>
            <Button
              type="link"
              danger
              onClick={(e) => handleDeleteButton(record)}
            >
              Delete
            </Button>
          </div>
        );
      },
      width: '200px'

    },
  ];
  const law_firm_columns = [
    {
      title: "Company Name",
      dataIndex: "law_firm_name",
      key: "law_firm_name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "100px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Person",
      dataIndex: "person_name",
      key: "person_name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Title",
      dataIndex: "person_title",
      key: "person_title",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Website",
      dataIndex: "site",
      key: "site",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
    {
      title: "Company",
      dataIndex: "person_linkedin_url",
      key: "person_linkedin_url",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
    {
      title: "Personal",
      dataIndex: "person_linkedin_url",
      key: "person_linkedin_url",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
    
  ];
  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="layout-content">
        <Row>
          <Col span={24}>
            <Card title="Leads">
              <Row justify="space-between" style={{ marginBottom: "10px" }}>
                <Col md={6}>
                
                  <Upload
                    accept=".csv"
                    customRequest={handleFileUpload}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Upload From CSV</Button>
                  </Upload>
                </Col>
                <Col md={6}>
                  <Row style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <Button
                      type="primary"
                      danger
                      onClick={handleDeleteList}
                      style={{ marginRight: "10px" }}
                      loading={isLoading}
                    >
                      Delete
                    </Button>
                    <Input
                      prefix={<SearchOutlined />}
                      size="small"
                      placeholder="Search"
                      value={searchLawFirm}
                      onChange={(e) => handleSearchLawFirmChange(e)}
                    />
                  </Row>
                </Col>
              </Row>
              <Table
                columns={list_columns}
                dataSource={filteredListData}
                scroll={{ x: true }}
                rowKey={(record) => record.id}
                loading={lawFirmDataLoading}
                pagination={true}
                bordered
                size="small"
                rowSelection={{
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows) => {
                    console.log(
                      `selectedRowKeys: ${selectedRowKeys}`,
                      "selectedRows: ",
                      selectedRows
                    );

                    setListDataForDelete(selectedRowKeys);
                  },
                  getCheckboxProps: (record) => ({
                    disabled: record.name === "Disabled User",
                    // Column configuration not to be checked
                    name: record.name,
                  }),
                  selectedRowKeys: listDataForDelete,
                }}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          title={modalTitle}
          visible={newLeadModalOpen}
          onOk={handleCancel}
          confirmLoading={isLoading}
          onCancel={handleCancel}
          width={1000}
        >
          <Row justify="center">
            <Col span={24}>
            <Table
                columns={law_firm_columns}
                dataSource={filteredLawFirmData}
                scroll={{ x: true }}
                rowKey={(record) => record.id}
                loading={lawFirmDataLoading}
                pagination={true}
                bordered
                size="small"
              />
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Delete Lead"
          visible={deleteLeadModalOpen}
          onOk={handleDeleteListItem}
          confirmLoading={isLoading}
          onCancel={handleCancel}
        >
          <h3>Are you sure?</h3>
        </Modal>

        <Modal
          title={"Import Subscribers"}
          visible={subscribersModalOpen}
          onOk={handleSaveNewListCSVClick}
          confirmLoading={isLoading}
          onCancel={handleCancel}
          width={1000}
        >
          <Row justify="center" style={{ marginBottom: '20px' }} gutter={50} >
            <Col span={12}>
              <div>Title</div>
              <Input
                size="small"
                value={listTitle}
                onChange={e => setListTitle(e.target.value)}
                placeholder="title of list"
              />
            </Col>
            <Col span={12}>
              <div>Tags</div>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                onChange={(e) => { setSelectedTags(e) }}
                value={selectedTags}
                options={tagList}
                size="large"
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Form layout="vertical" className="row-col">
                {Object.keys(csvData[0] || []).map((csvItem, key) => {
                  console.log(csvData[0]);
                  return (
                    <Row style={{ marginBottom: "20px" }} gutter={50} key={key}>
                      <Col span={12}>
                        <Input
                          size="small"
                          value={csvItem + " (" + csvData[0][csvItem] + ")"}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        <Select
                          style={{ width: "100%" }}
                          onChange={(option) =>
                            handleKeyChange(option, csvItem)
                          }
                          options={defaultKeysOption}
                          size="large"
                          placeholder="select option"
                        ></Select>
                      </Col>
                    </Row>
                  );
                })}
              </Form>
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }} gutter={50} >
            <Col span={12}>
              <div>Timezone</div>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="Select Timezone"
                onChange={(e) => { setSelectedTimezone(e) }}
                value={selectedTimezone}
                options={timezoneList}
                size="large"
              />
            </Col>
          </Row>
        </Modal>
      </div>
    </Context.Provider>
  );
}

export default List;
