/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { render } from "@testing-library/react";
import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
  Input,
  Tag,
  Space,
  notification
} from "antd";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { backend_api } from "../config";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const Context = React.createContext({
  name: "Default",
});
function Setting() {

  const voice_columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Rating",
      dataIndex: "average_rating",
      key: "average_rating",
      sorter: (a, b) => a.average_rating - b.average_rating,
    },
    {
      title: "Play",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" loading={playLoading && record.id == currentSelectedRecord.id} onClick={() => handlePlayClick(record)}>Play</Button>
        </Space>
      ),
    },
    {
      title: "",
      key: "voice",
      render: (_, record) => (
        currentSelectedRecord?.voice_id == record.id ? <Space size="middle">
          <div style={{ backgroundColor: "#059212", padding: '3px', color: 'white', borderRadius: '3px' }}>Using Now</div>
        </Space> :
          <Space size="middle">
            <Button type="primary" onClick={() => handleSubmit(record)}>Use This</Button>
          </Space>
      ),
    },
  ];

  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const [api, contextHolder] = notification.useNotification();
  const [voiceList, setVoiceList] = useState([])
  const [selectedVoice, setSelectedVoice] = useState()
  const [playLoading, setPlayLoading] = useState(false);
  const [voiceTableLoading, setVoiceTableLoaing] = useState(false);
  const [currentSelectedRecord, setCurrentSelectedRecord] = useState();
  const [dailyCallLimit, setDailyCallLimit] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  useEffect(() => {
    if (user == null) {
      history.push("/");
      return;
    }
    getCurrentVoice()
    getVoiceList()
    getCallLimit()
  }, [])

  const handlePlayClick = (record) => {
    setCurrentSelectedRecord(record)
    setPlayLoading(true);
    const headers = {
      Authorization: process.env.REACT_APP_BLAND_AUTH,
      responseType: 'arraybuffer',
    };
    const data = {
      text: "Hey There, this is Sally, How are you doing today?"
    }
    // API request
    axios.post(`https://api.bland.ai/v1/voices/${record.id}/sample`, data, { headers, responseType: 'arraybuffer' },).then(async (response) => {
      if (response.status === 200) {
        // Convert the response into an ArrayBuffer
        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        console.log(audioBlob)
        const audioUrl = URL.createObjectURL(audioBlob);
        // Create an Audio object and play the audio
        const audio = new Audio(audioUrl);
        console.log(audio)
        audio.play();

        // Clean up the URL object after the audio is done playing
        audio.onended = () => {
          URL.revokeObjectURL(audioUrl);
        };
      }
    }).catch(error => {
      console.log("error", error)
    }).finally(() => {
      setPlayLoading(false)
    });
  }

  const handleSubmit = (record) => {
    axios
      .post(`${backend_api}/voice`, { ...record, user_id: user.id })
      .then((response) => {
        getCurrentVoice()
      }).catch(error => {
        console.log(error)
      }).finally()
  }

  const getCurrentVoice = () => {
    axios
      .get(`${backend_api}/voice?user_id=${user.id}`)
      .then((response) => {
        if (response.data.success) {
          setCurrentSelectedRecord(response.data.message[0])
        }
      }).catch(error => {
        console.log(error)
      }).finally()
  }


  const getCallLimit = () => {
    axios
      .get(`${backend_api}/call-limit?user_id=${user.id}`)
      .then((response) => {
        if (response.data.success) {
          setDailyCallLimit(response.data.message[0].call_limit)
        }
      }).catch(error => {
        console.log(error)
        api.error({
          message: `Server Error`,
          description: (
            <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
          ),
          placement: "topRight",
        });
      }).finally()
  }

  const getVoiceList = () => {
    setVoiceTableLoaing(true)
    const headers = {
      'Authorization': process.env.REACT_APP_BLAND_AUTH,
    };

    // API request
    axios.get('https://api.bland.ai/v1/voices', { headers }).then((response) => {
      console.log("response", response)
      if (response.status === 200) {
        setVoiceList(response.data.voices)
      }
    }).catch(error => {
      console.log("error", error)
      api.error({
        message: `Server Error`,
        description: (
          <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
        ),
        placement: "topRight",
      });
    }).finally(() => {
      setVoiceTableLoaing(false)
    });
  }

  const handleCallLimitClick = () => {
    setUpdateLoading(true)
    axios
    .put(`${backend_api}/call-limit`, {userId: user.id, callLimit: dailyCallLimit})
    .then((response) => {
      if (response.data.success) {
        api.success({
          message: `Success`,
          description: (
            <Context.Consumer>{({ }) => `Updated Successfully`}</Context.Consumer>
          ),
          placement: "topRight",
        });
      } else {
        api.error({
          message: `Server Error`,
          description: (
            <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
          ),
          placement: "topRight",
        });
      }
    }).catch(error => {
      console.log(error)
      api.info({
        message: `Server Error`,
        description: (
          <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
        ),
        placement: "topRight",
      });
    }).finally(() => {
      setUpdateLoading(false)
    })
  }

  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );

  return (
    
    <Context.Provider value={contextValue}>
      {contextHolder}
    <>
      <div className="layout-content">
        <Row gutter={10} style={{marginBottom: '20px'}}>
          <Col style={{alignContent: 'center'}}>
            <div>Daily Call Limit</div>
          </Col>
          <Col>
            <Input onChange={(e) => setDailyCallLimit(e.target.value)} type="number" value={dailyCallLimit}/>
          </Col>
          <Col>
            <Button onClick={handleCallLimitClick} loading={updateLoading}>Apply</Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card title="Voice Setting">
              <Table
                columns={voice_columns}
                dataSource={voiceList}
                rowKey={(record) => {
                  return record.id
                }}
                loading={voiceTableLoading}
                size="small"
                bordered
              />
            </Card>
          </Col>
        </Row>

      </div>
    </>
    </Context.Provider>

  );
}

export default Setting;
