/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState, useMemo } from "react";

import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Space,
  Table,
  notification,
  Form,
  Modal,
  Select,
  TimePicker,
  DatePicker,
  Upload
} from "antd";
import { backend_api } from "../config";
import axios from "axios";
import { render } from "@testing-library/react";
import { PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import Papa from "papaparse";

// Extend dayjs with the weekday plugin
dayjs.extend(weekday);
dayjs.extend(localeData);

const Context = React.createContext({
  name: "Default",
});

const defaultKeysOption = [
  { value: "Name", label: "Name" },
  { value: "Title", label: "Title" },
  { value: "Company Name", label: "Company Name" },
  { value: "Phone Number", label: "Phone Number" },
  { value: "Email", label: "Email" },
  { value: "Company Linkedin Url", label: "Company Linkedin Url" },
  { value: "Person Linkedin Url", label: "Person Linkedin Url" },
  { value: "Company Address", label: "Company Address" },
  { value: "Modifier", label: "Modifier" },
];

function Campaign() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const [campaignData, setCampaignData] = useState([]);
  const [scheduleDataLoading, setScheduleDataLoading] = useState(false);
  const [searchLawFirm, setSearchLawFirm] = useState("");
  const [filteredCampaignData, setFilteredCampaignData] = useState([]);
  const [newLeadModalOpen, setNewScheduleModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [title, setTitle] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [deleteLeadModalOpen, setDeleteLeadModalOpen] = useState("");
  const [currentSelectedLead, setCurrentSelectedLead] = useState([]);
  const [scriptData, setScriptData] = useState([]);
  const [currentSelectedCampaignType, setcurrentSelectedCampaignType] =
    useState(1);
  const [subscribersModalOpen, setSubscribersModalOpen] = useState(false);

  const [scheduleTime, setScheduleTime] = useState("");
  const [currentSelectedCampaign, setCurrentSelectedCampaign] = useState();
  const [selectedListData, setSelectedListData] = useState();
  const [addLeadModalOpen, setAddLeadModalOpen] = useState(false);
  const [wholeLawFirmData, setWholeLawFirmData] = useState([]);
  const [wholeFilteredLawFirmData, setWholeFilteredLawFirmData] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [keyMapList, setKeyMapList] = useState([]);
  const [campaignReports, setCampaignReports] = useState([])
  useEffect(() => {
    if (user == null) {
      history.push("/");
      return;
    }
    getCampaign();
    getScripts();
    getLists();
  }, []);

  const getLists = () => {
    axios
    .get(`${backend_api}/list?user_id=${user.id}`)
    .then((response) => {
      if (response.status == 200) {
        console.log("Scripts", response.data);
        if (response.data.success) {
          
          setWholeFilteredLawFirmData(response.data.message)
        } else
          api.info({
            message: `Server Error`,
            description: (
              <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
            ),
            placement: "topRight",
          });
      } else {
        api.info({
          message: `Server Error`,
          description: (
            <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
          ),
          placement: "topRight",
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  const getScripts = () => {
    axios
      .get(`${backend_api}/script?user_id=${user.id}`)
      .then((response) => {
        if (response.status == 200) {
          console.log("Scripts", response.data);
          if (response.data.success) {
            console.log(response.data.message[0]);
            let tempScripts = [];
            response.data.message.forEach((script) => {
              tempScripts.push({
                ...script,
                value: script.id,
                label: script.title,
              });
            });
            setScriptData(tempScripts);
          } else
            api.info({
              message: `Server Error`,
              description: (
                <Context.Consumer>{({}) => `Error Occured`}</Context.Consumer>
              ),
              placement: "topRight",
            });
        } else {
          api.info({
            message: `Server Error`,
            description: (
              <Context.Consumer>{({}) => `Error Occured`}</Context.Consumer>
            ),
            placement: "topRight",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCampaign = () => {
    setScheduleDataLoading(true);
    axios
      .get(`${backend_api}/campaign?user=${user.id}`)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success) {
            setCampaignData(response.data.message);
            setFilteredCampaignData(response.data.message);
            setCampaignReports(response.data.campaigns);
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({ message: "Error", description: "Server Error" });
        }
      })
      .catch((error) => {
        notification.error({ message: "Error", description: "Server Error" });
      })
      .finally(() => {
        setScheduleDataLoading(false);
        setNewScheduleModalOpen(false);
      });
  };

  const clearLeadForm = () => { };

  const handleCancel = () => {
    setNewScheduleModalOpen(false);
    setDeleteLeadModalOpen(false);
    setSubscribersModalOpen(false);
    clearLeadForm();
  };

  const handleSaveNewSchedule = async () => {

    if (title == "" || selectedListData.length == 0 || scheduleTime == "") {
      notification.info({
        message: "Input correctly",
        description: "Some fields are missing.",
      });
      return;
    }

    const timeToCheck = new Date(scheduleTime);
    const startTime = new Date(timeToCheck.toDateString() + ' 08:00:00');
    const endTime = new Date(timeToCheck.toDateString() + ' 17:00:00');

    if (timeToCheck < startTime || timeToCheck > endTime) {
      notification.info({
        message: "Input correctly",
        description: "You must put the time correctly between 8AM to 5PM.",
      });
      return;
    }
    setIsLoading(true);

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const headers = {
      Authorization: process.env.REACT_APP_BLAND_AUTH,
    };
    const callResult = await axios.get(`https://api.bland.ai/v1/calls?start_date=${formattedDate}`, { headers })
    
    if (callResult.status == 200) {
      const callLimit = await axios.get(`${backend_api}/call-limit?user_id=${user.id}`)
      if(callLimit.status == 200 && callLimit.data.message[0].call_limit <= callResult.data.total_count) {
        notification.info({
          message: "Daily Call Limit",
          description: "You can't schedule today",
        });
        return;  
      }
    }


    if (modalTitle == "Add New Campaign") {

      const request = {
        user_id: user.id,
        title: title,
        list_id: selectedListData[0].id,
        script_id: currentSelectedCampaignType,
        date: scheduleTime,
        timezone: selectedListData[0].timezone
      };
      axios
        .post(`${backend_api}/campaign`, request)
        .then((response) => {
         
          if (response.status == 200) {
            console.log(response.data);
            if (response.data.success) {
              notification.success({
                message: "Success",
                description: "Successfully Created",
              });
              getCampaign();
            } else {
              notification.error({
                message: "Error",
                description: response.data.message,
              });
            }
          } else {
            notification.error({
              message: "Error",
              description: "Server Error",
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: "Error",
            description: "Server Error",
          });
        })
        .finally(() => {
          setIsLoading(false);
          setNewScheduleModalOpen(false);
          clearLeadForm();
        });

    } else {
      axios
        .delete(`${backend_api}/schedule?title=${currentSelectedCampaign.title}`)
        .then((response) => {
          if (response.status == 200) {
            if (response.data.success) {
              selectedListData.forEach((element) => {

                const request = {
                  user_id: user.id,
                  title: title,
                  lead_id: element.lead_id,
                  script_id: currentSelectedCampaignType,
                  date: scheduleTime,
                };

                axios
                  .post(`${backend_api}/schedule`, request)
                  .then((response) => {
                    if (response.status == 200) {
                      console.log(response.data);
                      if (response.data.success) {
                        notification.success({
                          message: "Success",
                          description: "Successfully Created",
                        });
                        getCampaign();
                      } else {
                        notification.error({
                          message: "Error",
                          description: response.data.message,
                        });
                      }
                    } else {
                      notification.error({
                        message: "Error",
                        description: "Server Error",
                      });
                    }
                  })
                  .catch((error) => {
                    notification.error({
                      message: "Error",
                      description: "Server Error",
                    });
                  })
                  .finally(() => {
                    setIsLoading(false);
                    setNewScheduleModalOpen(false);
                    clearLeadForm();
                  });
              });
            } else {
              notification.error({
                message: "Error",
                description: response.data.message,
              });
            }
          } else {
            notification.error({ message: "Error", description: "Server Error" });
          }
        })
        .catch((error) => {
          notification.error({ message: "Error", description: "Server Error" });
        })
        .finally(() => {
          setIsLoading(false);
          setDeleteLeadModalOpen(false);
        });


    }
  };

  const handleSearchLawFirmChange = (e) => {
    setSearchLawFirm(e.target.value);
    const searchKey = e.target.value;
    filterDataAsync(campaignData, searchKey).then((filteredData) => {
      setFilteredCampaignData(filteredData);
    });
  };

  const filterDataAsync = (data, key) => {
    return new Promise((resolve) => {
      const filteredData = data.filter((item) =>
        item.campaign_name?.toLowerCase().includes(key.toLowerCase())
      );
      resolve(filteredData);
    });
  };

  const handleFileUpload = ({ file }) => {
    setSubscribersModalOpen(true);
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setCsvData(result.data);
          let keyMapListTemp = [];
          Object.keys(result.data[0]).map((originalKey) => {
            keyMapListTemp.push({ original: originalKey, alter: originalKey });
          });
          setKeyMapList(keyMapListTemp);
        },
        error: (error) => {
          notification.error({
            message: "Error",
            description: "Failed to upload",
          });
          console.error(error);
        },
      });
    };

    reader.onerror = () => {
      notification.error({
        message: "Error",
        description: "Failed to read file",
      });
    };

    reader.readAsText(file);
  };

  const handleDetailButton = (schedule) => {
    setModalTitle("Detail Schedule");
    console.log("lead", schedule);

    setcurrentSelectedCampaignType(
      scriptData.find((script) => script.title == schedule.script_title).value
    );
    setCurrentSelectedCampaign(schedule);
    setTitle(schedule.title);
    setNewScheduleModalOpen(true);
    setScheduleTime(schedule.date);
    axios
      .get(
        `${backend_api}/schedule_detail?title=${encodeURIComponent(
          schedule.title
        )}&user_id=${encodeURIComponent(user.id)}`
      )
      .then((res) => {
        if (res.data.success) {
          setSelectedListData(res.data.message);
        } else {
          notification.error({
            message: "Error",
            description: res.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteButton = (record) => {
    setDeleteLeadModalOpen(true);
    setCurrentSelectedCampaign(record);
  };

  const handleRemoveButton = (record) => {
    console.log(record, selectedListData)
    setSelectedListData(selectedListData.filter(item => item["Phone Number"] != record["Phone Number"]))
  }

  const handleDeleteCampaign = () => {
    axios
      .delete(`${backend_api}/campaign?id=${currentSelectedCampaign.id}`)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success) {
            notification.success({
              message: "Success",
              description: "Schedule deleted",
            });
            getCampaign();
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({ message: "Error", description: "Server Error" });
        }
      })
      .catch((error) => {
        notification.error({ message: "Error", description: "Server Error" });
      })
      .finally(() => {
        setIsLoading(false);
        setDeleteLeadModalOpen(false);
      });
  };
  const handleSaveNewLeadCSVClick = () => {
    // setIsLoading(true);
    const tempCsvData = csvData;
    const newCsvData = tempCsvData.map((csvItem) => {
      keyMapList.map((keyMap) => {
        if (keyMap.alter !== keyMap.original) {
          csvItem[keyMap.alter] = csvItem[keyMap.original];
          delete csvItem[keyMap.original];
        }
      });
      return csvItem;
    });
    setSelectedListData(newCsvData)
    setSubscribersModalOpen(false);
    clearLeadForm()
    // axios
    //   .post(`${backend_api}/leadcsv`, {
    //     request: newCsvData,
    //     user_id: user.id,
    //   })
    //   .then((response) => {
    //     if (response.status == 200) {
    //       if (response.data.success) {
    //         notification.success({
    //           message: "Success",
    //           description: `Successfully Created total ${newCsvData.length} / ${response.data.message.success}`,
    //         });
    //         getLeads();
    //       } else {
    //         notification.error({
    //           message: "Error",
    //           description: response.data.message,
    //         });
    //       }
    //     } else {
    //       notification.error({
    //         message: "Error",
    //         description: "Server Error",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     notification.error({
    //       message: "Error",
    //       description: "Server Error",
    //     });
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //     setSubscribersModalOpen(false);
    //     clearLeadForm();
    //   });
  };
  const handleKeyChange = (option, original) => {
    let keyMapIndex = keyMapList.findIndex(
      (keyMap) => keyMap.original == original
    );
    if (keyMapIndex == -1) {
      keyMapList.push({ original, alter: option });
    } else {
      keyMapList[keyMapIndex].alter = option;
    }
    setKeyMapList(keyMapList);
  };
  const handleScheduleChange = (value) => {
    setcurrentSelectedCampaignType(value);
  };

  const handleAddLeadsClick = (value) => {
    setAddLeadModalOpen(true);
   
  };

  const handleAddLead = () => {
    setAddLeadModalOpen(false);
  };

  const handleAddLeadCancel = (value) => {
    setAddLeadModalOpen(false);
    setSelectedLeads([]);
  };
  const schedule_columns = [
    {
      title: "Campaign Id",
      dataIndex: "campaign_id",
      key: "campaign_id",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Title",
      dataIndex: "campaign_name",
      key: "campaign_name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Leads",
      dataIndex: "lead_count",
      key: "lead_count",
      width: "100px",
    },
    {
      title: "Script",
      dataIndex: "script_title",
      key: "script_title",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Schedule Time",
      dataIndex: "schedule_time",
      key: "schedule_time",
      render(data, record) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data?.substring(0, 16).replace("T", " ")}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    
    {
      title: "Report",
      render(_, record) {
        
        return (
          <div style={{ paddingLeft: '9px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div style={{ borderRight: '1px solid rgb(238, 238, 238)', width: '33.3%', overflow: 'clip', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', paddingRight: '10px' }}>
                <div style={{ fontSize: '18px', fontWeight: '500' }}>{record.lead_count }</div>
                <div style={{ fontSize: '10px', fontWeight: '400' }}>Total Calls</div>
              </div>
              <div style={{ borderRight: '1px solid rgb(238, 238, 238)', width: '33.3%', overflow: 'clip', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', paddingRight: '10px' }}>
                <div style={{ fontSize: '18px', fontWeight: '500' }}>{record.calls_sent }</div>
                <div style={{ fontSize: '10px', fontWeight: '400' }}>Calls Sent</div>
              </div>
              <div style={{ borderRight: '1px solid rgb(238, 238, 238)', width: '33.3%', overflow: 'clip', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px', paddingRight: '10px', justifyContent: 'center' }}>
                <div style={{ fontSize: '18px', fontWeight: '500' }}>{record.answered_count }</div>
                <div style={{ fontSize: '10px', fontWeight: '400' }}>Answered</div>
              </div>
              <div style={{ borderRight: '1px solid rgb(238, 238, 238)', width: '33.3%', overflow: 'clip', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px', paddingRight: '10px', justifyContent: 'center' }}>
                <div style={{ fontSize: '18px', fontWeight: '500' }}>{record.sms_sent}</div>
                <div style={{ fontSize: '10px', fontWeight: '400' }}>Not Answered</div>
              </div>
              <div style={{ width: '33.3%', overflow: 'clip', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: '10px', paddingRight: '10px', justifyContent: 'center' }}>
                <div style={{ fontSize: '18px', fontWeight: '500' }}>{record.sms_sent }</div>
                <div style={{ fontSize: '10px', fontWeight: '400' }}>SMS sent</div>
              </div>
            </div>
          </div>
        );
      },
      width: "100px",
    },
    {
      title: "Delete",
      render(_, record) {
        return (
          <div>
            <Button
              type="link"
              danger
              onClick={(e) => handleDeleteButton(record)}
            >
              Delete
            </Button>
          </div>
        );
      },
      width: "100px",
    },
  ];


  const list_columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
    },
    {
      title: "Lead Count",
      dataIndex: "lead_count",
      key: "lead_count",
    },
    {
      title: "Timezone",
      dataIndex: "timezone",
      key: "timezone",
    },
  ];

  const lead_firm_columns = [
    {
      title: "Company Name",
      dataIndex: "Company Name",
      key: "Company Name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Phone Number",
      dataIndex: "Phone Number",
      key: "Phone Number",
      width: "100px",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Person",
      dataIndex: "Name",
      key: "Name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Remove",
      render(_, record) {
        return (
          <div>
            <Button
              type="link"
              danger
              onClick={(e) => handleRemoveButton(record)}
            >
              Remove
            </Button>
          </div>
        );
      },
    },
  ];
  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );

  const law_firm_columns = [
    {
      title: "Company Name",
      dataIndex: "law_firm_name",
      key: "law_firm_name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "100px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Person",
      dataIndex: "person_name",
      key: "person_name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Title",
      dataIndex: "person_title",
      key: "person_title",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Website",
      dataIndex: "site",
      key: "site",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
    {
      title: "Company",
      dataIndex: "person_linkedin_url",
      key: "person_linkedin_url",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
    {
      title: "Personal",
      dataIndex: "person_linkedin_url",
      key: "person_linkedin_url",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
  ];
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="layout-content">
        <Row>
          <Col span={24}>
            <Card title="Campaign">
              <Row justify="space-between" style={{ marginBottom: "10px" }}>
                <Col md={6}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setNewScheduleModalOpen(true);
                      setModalTitle("Add New Campaign");
                      setCurrentSelectedCampaign({
                        title: "",
                        script_title: "",
                      });
                      setcurrentSelectedCampaignType(scriptData[0]?.value);
                      setTitle("");
                      setSelectedListData([]);
                      setScheduleTime("");
                    }}
                  >
                    <PlusOutlined />
                    Add New
                  </Button>
                </Col>
                <Col md={6}>
                  <Input
                    prefix={<SearchOutlined />}
                    size="small"
                    placeholder="Search"
                    value={searchLawFirm}
                    onChange={(e) => handleSearchLawFirmChange(e)}
                  />
                </Col>
              </Row>
              <Table
                columns={schedule_columns}
                dataSource={filteredCampaignData}
                scroll={{ x: true }}
                rowKey={(record) => record.campaign_id}
                loading={scheduleDataLoading}
                pagination={false}
                bordered
                size="small"
              />
            </Card>
          </Col>
        </Row>
        <Modal
          title={modalTitle}
          visible={newLeadModalOpen}
          onOk={handleSaveNewSchedule}
          confirmLoading={isLoading}
          onCancel={handleCancel}
          width={1000}
        >
          <Row justify="center">
            <Col span={24}>
              <Form layout="vertical" className="row-col">
                <Row style={{ marginBottom: "20px" }} gutter={50}>
                  <Col span={12}>
                    <p>Title</p>
                    <Input
                      placeholder="Title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      value={title}
                      defaultValue={title}
                    />
                  </Col>
                  <Col span={12}>
                    <p>Script</p>
                    <Select
                      defaultValue={
                        modalTitle == "Add New Campaign"
                          ? scriptData[0]?.value
                          : currentSelectedCampaignType
                      }
                      value={currentSelectedCampaignType}
                      onChange={handleScheduleChange}
                      options={scriptData}
                      style={{ width: "100%" }}
                      size="large"
                    ></Select>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Row
                      justify="space-between"
                      align="bottom"
                      style={{ margin: "20px" }}
                    >
                      <p>List</p>
                      
                      {/* <Button
                        size="small"
                        type="primary"
                        onClick={handleAddLeadsClick}
                      >
                        Add
                      </Button> */}
                    </Row>
                    {/* <Table
                      columns={lead_firm_columns}
                      dataSource={selectedListData}
                      scroll={{ x: true }}
                      rowKey={(record) => record.id}
                      loading={scheduleDataLoading}
                      pagination={false}
                      bordered
                      size="small"
                    /> */}
                    <Table
                      columns={list_columns}
                      dataSource={wholeFilteredLawFirmData}
                      scroll={{ x: true }}
                      rowKey={(record) => record.id}
                      loading={scheduleDataLoading}
                      pagination={false}
                      bordered
                      rowSelection={{
                        type: "radio",
                        onChange: (selectedRowKeys, selectedRows) => {
                          console.log(selectedRows)
                          setSelectedListData(selectedRows);
                        },
                        
                        getCheckboxProps: (record) => ({
                          disabled: record.name === "Disabled User",
                          // Column configuration not to be checked
                          name: record.name,
                        }),
                        selectedRowKeys: selectedListData?.map((row) => row.id),
                      }}
                      size="small"
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                  <Row gutter={10}>
                    <Col span={24}>
                      <p>Date</p>
                      <DatePicker
                        showTime={{ format: 'HH:mm' }}
                        onChange={(_, dateString) => {
                          setScheduleTime(`${dateString}:00`);
                        }}
                        value={
                          modalTitle == "Add New Campaign"
                            ? undefined
                            : dayjs(scheduleTime)
                        }
                        allowClear={false}
                        format="YYYY-MM-DD HH:mm"
                      />
                    </Col>
                  </Row>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Select A List"
          visible={addLeadModalOpen}
          onOk={handleAddLead}
          confirmLoading={isLoading}
          onCancel={handleAddLeadCancel}
          width={1000}
        >
          <Row justify="center">
            <Col span={24}>
              <Form layout="vertical" className="row-col">
                <Row>
                  <Col span={24}>
                    <Table
                      columns={list_columns}
                      dataSource={wholeFilteredLawFirmData}
                      scroll={{ x: true }}
                      rowKey={(record) => record.id}
                      loading={scheduleDataLoading}
                      pagination={false}
                      bordered
                      rowSelection={{
                        type: "radio",
                        onChange: (selectedRowKeys, selectedRows) => {
                          console.log(selectedRows)
                          setSelectedListData(selectedRows);
                        },
                        
                        getCheckboxProps: (record) => ({
                          disabled: record.name === "Disabled User",
                          // Column configuration not to be checked
                          name: record.name,
                        }),
                        selectedRowKeys: selectedListData?.map((row) => row.id),
                      }}
                      size="small"
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Delete Schedule"
          visible={deleteLeadModalOpen}
          onOk={handleDeleteCampaign}
          confirmLoading={isLoading}
          onCancel={handleCancel}
        >
          <h3>Are you sure?</h3>
        </Modal>
        <Modal
          title={"Import Subscribers"}
          visible={subscribersModalOpen}
          onOk={handleSaveNewLeadCSVClick}
          confirmLoading={isLoading}
          onCancel={handleCancel}
          width={1000}
        >
          <Row justify="center">
            <Col span={24}>
              <Form layout="vertical" className="row-col">
                {Object.keys(csvData[0] || []).map((csvItem, key) => {
                  console.log(csvData[0]);
                  return (
                    <Row style={{ marginBottom: "20px" }} gutter={50} key={key}>
                      <Col span={12}>
                        <Input
                          size="small"
                          value={csvItem + " (" + csvData[0][csvItem] + ")"}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        <Select
                          style={{ width: "100%" }}
                          onChange={(option) =>
                            handleKeyChange(option, csvItem)
                          }
                          options={defaultKeysOption}
                          size="large"
                          placeholder="select option"
                        ></Select>
                      </Col>
                    </Row>
                  );
                })}
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    </Context.Provider>
  );
}

export default Campaign;
