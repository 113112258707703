/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState, useMemo } from "react";

import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Space,
  Table,
  notification,
  Form,
  Modal,
  Select,
  Upload,
} from "antd";
import { backend_api } from "../config";
import axios from "axios";
import { render } from "@testing-library/react";
import {
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import TextArea from "antd/lib/input/TextArea";
import Papa from "papaparse";
const Context = React.createContext({
  name: "Default",
});

const defaultKeysOption = [
  { value: "Name", label: "Name" },
  { value: "Title", label: "Title" },
  { value: "Company Name", label: "Company Name" },
  { value: "Phone Number", label: "Phone Number" },
  { value: "Email", label: "Email" },
  { value: "Company Linkedin Url", label: "Company Linkedin Url" },
  { value: "Person Linkedin Url", label: "Person Linkedin Url" },
  { value: "Company Address", label: "Company Address" },
  { value: "Modifier", label: "Modifier" },
];

function Home() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  const [lawFirmData, setLawFirmData] = useState([]);
  const [lawFirmDataLoading, setLawFirmDataLoading] = useState(false);
  const [searchLawFirm, setSearchLawFirm] = useState("");
  const [filteredLawFirmData, setFilteredLawFirmData] = useState([]);
  const [newLeadModalOpen, setNewLeadModalOpen] = useState(false);
  const [subscribersModalOpen, setSubscribersModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [law_firm_name, setLawFirmName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [personName, setPersonName] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [modifier, setModifier] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [personLinkedinUrl, setPersonLinkedinUrl] = useState("");
  const [companyLinkedinUrl, setCompanyLinkedinUrl] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [title, setTitle] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [deleteLeadModalOpen, setDeleteLeadModalOpen] = useState("");
  const [currentSelectedLead, setCurrentSelectedLead] = useState([]);
  const [scriptData, setScriptData] = useState([]);
  const [currentSelectedScript, setCurrentSelectedScript] = useState();
  const [csvData, setCsvData] = useState([]);
  const [keyMapList, setKeyMapList] = useState([]);
  const [lawFirmDataForDelete, setLawFirmDataForDelete] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    if (user == null) {
      history.push("/");
      return;
    }
    // axios.get('https://docs.google.com/spreadsheets/d/e/2PACX-1vT7Qn5nABYDjLb73ZyD3cgqA68rp-wsw601TmwQLtztyQGRcQV1bI6WBV2IA0m9Utns3GdBFLql1Rj2/pub?gid=0&single=true&output=csv').then((response) => {
    //   if(response.status == 200) {
    //     let parsingData= parseCSV(response.data)
    //     setLawFirmData(parsingData)
    //     setFilteredLawFirmData(parsingData)
    //   }
    // })

    getLeads();
    getScripts();
    getTags();
  }, []);

  const getTags = () => {
    const auth = Buffer.from(`${process.env.REACT_APP_FLUENT_CRM_USERNAME}:${process.env.REACT_APP_FLUENT_CRM_PASSWORDD}`).toString('base64');

    axios.get(`${process.env.REACT_APP_FLUENT_CRM_API}/tags`, {
      headers: {
        Authorization: `Basic ${auth}`
      }
    }).then((response) => {
      setTagList([])
      if (response.status == 200) {
        response.data.tags.data.forEach(element => {
          tagList.push({
            label: element.title,
            value: element.id,
          });
        });
        setTagList(tagList)
      }
    })
  }

  const getScripts = () => {
    axios
      .get(`${backend_api}/script?user_id=${user.id}`)
      .then((response) => {
        if (response.status == 200) {
          console.log("Scripts", response.data);
          if (response.data.success) {
            let tempScripts = [];
            response.data.message.forEach((script) => {
              tempScripts.push({
                ...script,
                value: script.id,
                label: script.title,
              });
            });
            setScriptData(tempScripts);
          } else
            api.info({
              message: `Server Error`,
              description: (
                <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
              ),
              placement: "topRight",
            });
        } else {
          api.info({
            message: `Server Error`,
            description: (
              <Context.Consumer>{({ }) => `Error Occured`}</Context.Consumer>
            ),
            placement: "topRight",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getLeads = () => {
    setLawFirmDataLoading(true);
    axios
      .get(`${backend_api}/lead?user=${user.id}`)
      .then((response) => {
        if (response.status == 200) {
          console.log(response.data);
          if (response.data.success) {
            setLawFirmData(response.data.message);
            setFilteredLawFirmData(response.data.message);
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({ message: "Error", description: "Server Error" });
        }
      })
      .catch((error) => {
        notification.error({ message: "Error", description: "Server Error" });
      })
      .finally(() => {
        setLawFirmDataLoading(false);
        setNewLeadModalOpen(false);
      });
  };
  const handleFileUpload = ({ file }) => {
    setSubscribersModalOpen(true);
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setCsvData(result.data);
          let keyMapListTemp = [];
          Object.keys(result.data[0]).map((originalKey) => {
            keyMapListTemp.push({ original: originalKey, alter: originalKey });
          });
          setKeyMapList(keyMapListTemp);
        },
        error: (error) => {
          notification.error({
            message: "Error",
            description: "Failed to upload",
          });
          console.error(error);
        },
      });
    };

    reader.onerror = () => {
      notification.error({
        message: "Error",
        description: "Failed to read file",
      });
    };

    reader.readAsText(file);
  };

  const handleSaveNewLeadCSVClick = () => {
    setIsLoading(true);
    const tempCsvData = csvData;
    console.log({ tempCsvData });
    const newCsvData = tempCsvData.map((csvItem) => {
      keyMapList.map((keyMap) => {
        if (keyMap.alter !== keyMap.original) {
          csvItem[keyMap.alter] = csvItem[keyMap.original];
          delete csvItem[keyMap.original];
        }
      });
      return csvItem;
    });
    console.log({ newCsvData });
    axios
      .post(`${backend_api}/leadcsv`, {
        request: newCsvData,
        user_id: user.id,
        tags: selectedTags
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success) {
            notification.success({
              message: "Success",
              description: `Successfully Created total ${newCsvData.length} / ${response.data.message.success}`,
            });
            getLeads();
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({
            message: "Error",
            description: "Server Error",
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "Server Error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        setSubscribersModalOpen(false);
        clearLeadForm();
      });
  };
  const handleKeyChange = (option, original) => {
    let keyMapIndex = keyMapList.findIndex(
      (keyMap) => keyMap.original == original
    );
    if (keyMapIndex == -1) {
      keyMapList.push({ original, alter: option });
    } else {
      keyMapList[keyMapIndex].alter = option;
    }
    setKeyMapList(keyMapList);
  };

  const parseCSV = (csvText) => {
    const rows = csvText.split(/\r?\n/); // Split CSV text into rows, handling '\r' characters
    const headers = rows[0]
      .replace(/\s+/g, "")
      .split(",")
      .filter((item) => item !== ""); // Extract headers (assumes the first row is the header row)
    const data = []; // Initialize an array to store parsed data
    for (let i = 1; i < rows.length; i++) {
      const modifiedText = rows[i].replace(/("[^"]*")|,/g, (match, group1) => {
        // If match is within double quotes, return it unchanged
        if (group1) {
          return group1;
        } else {
          // Replace comma outside of quotes with @@@
          return "@@@";
        }
      });

      // Replace remaining commas with @@@
      const rowData = modifiedText.split("@@@"); // Split the row, handling '\r' characters
      const rowObject = {};
      for (let j = 0; j < headers.length; j++) {
        rowObject[headers[j]] = rowData[j];
      }
      data.push(rowObject);
    }
    return data;
  };

  const clearLeadForm = () => {
    setLawFirmName("");
    setEmail("");
    setCompanyAddress("");
    setCompanyLinkedinUrl("");
    setPersonLinkedinUrl("");
    setPhoneNumber("");
    setPersonName("");
    setTitle("");
    setSiteUrl("");
    setModifier("");
    setCsvData([]);
    setSelectedTags([])
  };

  const handleCancel = () => {
    setNewLeadModalOpen(false);
    setDeleteLeadModalOpen(false);
    setSubscribersModalOpen(false);
    clearLeadForm();
  };

  const handleSaveNewLead = () => {
    const request = {
      law_firm_name: law_firm_name,
      phone_number: phoneNumber,
      email: email,
      person_name: personName,
      person_title: title,
      site: siteUrl,
      company_address: companyAddress,
      person_linkedin_url: personLinkedinUrl,
      company_linkedin_url: companyLinkedinUrl,
      modifier: modifier,
      user_id: user.id,
      script_id: currentSelectedScript,
      tags: selectedTags
    };
    setIsLoading(true);
    if (modalTitle == "Add New Lead") {
      axios
        .post(`${backend_api}/lead`, request)
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);
            if (response.data.success) {
              notification.success({
                message: "Success",
                description: "Successfully Created",
              });
              getLeads();
            } else {
              notification.error({
                message: "Error",
                description: response.data.message,
              });
            }
          } else {
            notification.error({
              message: "Error",
              description: "Server Error",
            });
          }
        })
        .catch((error) => {
          notification.error({ message: "Error", description: "Server Error" });
        })
        .finally(() => {
          setIsLoading(false);
          setNewLeadModalOpen(false);
          clearLeadForm();
        });
    } else {
      console.log(currentSelectedLead);
      axios
        .put(`${backend_api}/lead/${currentSelectedLead.id}`, request)
        .then((response) => {
          if (response.status == 200) {
            console.log(response.data);
            if (response.data.success) {
              notification.success({
                message: "Success",
                description: "Successfully Updated",
              });
              getLeads();
            } else {
              notification.error({
                message: "Error",
                description: response.data.message,
              });
            }
          } else {
            notification.error({
              message: "Error",
              description: "Server Error",
            });
          }
        })
        .catch((error) => {
          notification.error({ message: "Error", description: "Server Error" });
        })
        .finally(() => {
          setIsLoading(false);
          setNewLeadModalOpen(false);
          clearLeadForm();
        });
    }
  };

  const handleSearchLawFirmChange = (e) => {
    setSearchLawFirm(e.target.value);
    const searchKey = e.target.value;
    filterDataAsync(lawFirmData, searchKey).then((filteredData) => {
      setFilteredLawFirmData(filteredData);
    });
  };

  const filterDataAsync = (data, key) => {
    return new Promise((resolve) => {
      const filteredData = data.filter(
        (item) =>
          item.law_firm_name?.toLowerCase().includes(key.toLowerCase()) ||
          item.phone_number?.toLowerCase().includes(key.toLowerCase()) ||
          item.email?.toLowerCase().includes(key.toLowerCase()) ||
          item.person_name?.toLowerCase().includes(key.toLowerCase()) ||
          item.person_title?.toLowerCase().includes(key.toLowerCase())
      );
      resolve(filteredData);
    });
  };

  const handleDetailButton = (lead) => {
    setModalTitle("Detail Lead");
    console.log("lead", lead);
    setCurrentSelectedLead(lead);
    setLawFirmName(lead.law_firm_name);
    setEmail(lead.email);
    setCompanyAddress(lead.company_address);
    setCompanyLinkedinUrl(lead.company_linkedin_url);
    setPersonLinkedinUrl(lead.person_linkedin_url);
    setPhoneNumber(lead.phone_number);
    setPersonName(lead.person_name);
    setTitle(lead.person_title);
    setSiteUrl(lead.site);
    setModifier(lead.modifier);
    setNewLeadModalOpen(true);
    setCurrentSelectedScript(lead.script_id);
  };

  const handleDeleteButton = (record) => {
    setDeleteLeadModalOpen(true);
    setCurrentSelectedLead(record);
  };

  const handleDeleteLeads = () => {
    setIsLoading(true)
    axios
      .post(`${backend_api}/delete_leads`, { lawFirmDataForDelete })
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success) {
            notification.success({
              message: "Success",
              description: "Lead deleted",
            });
            getLeads();
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({ message: "Error", description: "Server Error" });
        }
      })
      .catch((error) => {
        notification.error({ message: "Error", description: "Server Error" });
      })
      .finally(() => {
        setIsLoading(false);
        setDeleteLeadModalOpen(false);
      });
  };
  const handleDeleteLead = () => {
    axios
      .delete(`${backend_api}/lead?id=${currentSelectedLead.id}`)
      .then((response) => {
        if (response.status == 200) {
          if (response.data.success) {
            notification.success({
              message: "Success",
              description: "Lead deleted",
            });
            getLeads();
          } else {
            notification.error({
              message: "Error",
              description: response.data.message,
            });
          }
        } else {
          notification.error({ message: "Error", description: "Server Error" });
        }
      })
      .catch((error) => {
        notification.error({ message: "Error", description: "Server Error" });
      })
      .finally(() => {
        setIsLoading(false);
        setDeleteLeadModalOpen(false);
      });
  };

  const handleScriptChange = (value) => {
    console.log(`selected ${value}`);
    setCurrentSelectedScript(value);
  };

  const law_firm_columns = [
    {
      title: "Company Name",
      dataIndex: "law_firm_name",
      key: "law_firm_name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "200px",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "100px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Person",
      dataIndex: "person_name",
      key: "person_name",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Title",
      dataIndex: "person_title",
      key: "person_title",
      render(data) {
        return <div style={{ whiteSpace: "pre-wrap" }}>{data}</div>;
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "100px",
    },
    {
      title: "Website",
      dataIndex: "site",
      key: "site",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
    {
      title: "Company",
      dataIndex: "person_linkedin_url",
      key: "person_linkedin_url",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
    {
      title: "Personal",
      dataIndex: "person_linkedin_url",
      key: "person_linkedin_url",
      render: (link) => {
        return (
          <div style={{ whiteSpace: "pre-wrap", width: "50px" }}>
            <a href={link} target="_blank" style={{ whiteSpace: "pre-wrap" }}>
              Visit
            </a>
          </div>
        );
      },
      textWrap: "word-break",
      ellipsis: true,
      width: "50px",
    },
    // {
    //   title: "Call",
    //   render(_, record) {
    //     return (
    //       <div>
    //         <Button type="primary" onClick={(e) => handleCallButton(record)}>
    //           Call
    //         </Button>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "View",
      render(_, record) {
        return (
          <div>
            <Button type="link" onClick={(e) => handleDetailButton(record)}>
              Detail
            </Button>
          </div>
        );
      },
    },
    {
      title: "Delete",
      render(_, record) {
        return (
          <div>
            <Button
              type="link"
              danger
              onClick={(e) => handleDeleteButton(record)}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="layout-content">
        <Row>
          <Col span={24}>
            <Card title="Leads">
              <Row justify="space-between" style={{ marginBottom: "10px" }}>
                <Col md={6}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setNewLeadModalOpen(true);
                      setModalTitle("Add New Lead");
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    <PlusOutlined />
                    Add New
                  </Button>
                  <Upload
                    accept=".csv"
                    customRequest={handleFileUpload}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Upload From CSV</Button>
                  </Upload>
                </Col>
                <Col md={6}>
                  <Row style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    <Button
                      type="primary"
                      danger
                      onClick={handleDeleteLeads}
                      style={{ marginRight: "10px" }}
                      loading={isLoading}
                    >
                      Delete
                    </Button>
                    <Input
                      prefix={<SearchOutlined />}
                      size="small"
                      placeholder="Search"
                      value={searchLawFirm}
                      onChange={(e) => handleSearchLawFirmChange(e)}
                    />
                  </Row>
                </Col>
              </Row>
              <Table
                columns={law_firm_columns}
                dataSource={filteredLawFirmData}
                scroll={{ x: true }}
                rowKey={(record) => record.id}
                loading={lawFirmDataLoading}
                pagination={true}
                bordered
                size="small"
                rowSelection={{
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows) => {
                    console.log(
                      `selectedRowKeys: ${selectedRowKeys}`,
                      "selectedRows: ",
                      selectedRows
                    );

                    setLawFirmDataForDelete(selectedRowKeys);
                  },
                  getCheckboxProps: (record) => ({
                    disabled: record.name === "Disabled User",
                    // Column configuration not to be checked
                    name: record.name,
                  }),
                  selectedRowKeys: lawFirmDataForDelete,
                }}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          title={modalTitle}
          visible={newLeadModalOpen}
          onOk={handleSaveNewLead}
          confirmLoading={isLoading}
          onCancel={handleCancel}
          width={1000}
        >
          <Row justify="center">
            <Col span={24}>
              <Form layout="vertical" className="row-col">
                <Row style={{ marginBottom: "20px" }} gutter={50}>
                  <Col span={12}>
                    <p>Company Name</p>
                    <Input
                      placeholder="Company Name"
                      onChange={(e) => {
                        setLawFirmName(e.target.value);
                      }}
                      value={law_firm_name}
                      defaultValue={law_firm_name}
                    />
                  </Col>
                  <Col span={12}>
                    <p>Email</p>
                    <Input
                      placeholder="Email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      defaultValue={email}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }} gutter={50}>
                  <Col span={12}>
                    <p>Phone Number</p>
                    <Input
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      value={phoneNumber}
                      defaultValue={phoneNumber}
                    />
                  </Col>
                  <Col span={12}>
                    <p>Person Name</p>
                    <Input
                      placeholder="Person Name"
                      onChange={(e) => {
                        setPersonName(e.target.value);
                      }}
                      value={personName}
                      defaultValue={personName}
                    />
                  </Col>
                </Row>

                <Row style={{ marginBottom: "20px" }} gutter={50}>
                  <Col span={12}>
                    <p>Title</p>
                    <Input
                      placeholder="Title"
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      value={title}
                      defaultValue={title}
                    />
                  </Col>
                  <Col span={12}>
                    <p>Company Site</p>
                    <Input
                      placeholder="Site"
                      onChange={(e) => {
                        setSiteUrl(e.target.value);
                      }}
                      value={siteUrl}
                      defaultValue={siteUrl}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "20px" }} gutter={50}>
                  <Col span={12}>
                    <p>Company Linkedin Url</p>
                    <Input
                      placeholder="Company Linkedin Url"
                      onChange={(e) => {
                        setCompanyLinkedinUrl(e.target.value);
                      }}
                      value={companyLinkedinUrl}
                      defaultValue={companyLinkedinUrl}
                    />
                  </Col>
                  <Col span={12}>
                    <p>Person Linkedin Url</p>
                    <Input
                      placeholder="Person Name"
                      onChange={(e) => {
                        setPersonLinkedinUrl(e.target.value);
                      }}
                      value={personLinkedinUrl}
                      defaultValue={personLinkedinUrl}
                    />
                  </Col>
                </Row>

                <Row style={{ marginBottom: "20px" }} gutter={50}>
                  <Col span={12}>
                    <p>Company Address</p>
                    <Input
                      placeholder="Company address(13119 Professional Dr., Ste. 200 Jacksonville, FL 32225)"
                      onChange={(e) => {
                        setCompanyAddress(e.target.value);
                      }}
                      value={companyAddress}
                      defaultValue={companyAddress}
                    />
                  </Col>
                  <Col span={12}>
                    <p>Tags</p>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      onChange={(e) => { setSelectedTags(e) }}
                      value={selectedTags}
                      options={tagList}
                      size="large"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <p>Modifier</p>
                    <TextArea
                      placeholder="I visited your website, johnlaw.com and saw that you website loads slowly..."
                      onChange={(e) => {
                        setModifier(e.target.value);
                      }}
                      value={modifier}
                      defaultValue={modifier}
                      rows={4}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Delete Lead"
          visible={deleteLeadModalOpen}
          onOk={handleDeleteLead}
          confirmLoading={isLoading}
          onCancel={handleCancel}
        >
          <h3>Are you sure?</h3>
        </Modal>

        <Modal
          title={"Import Subscribers"}
          visible={subscribersModalOpen}
          onOk={handleSaveNewLeadCSVClick}
          confirmLoading={isLoading}
          onCancel={handleCancel}
          width={1000}
        >
          <Row style={{ marginBottom: "20px" }} gutter={50} >
            <Col span={12}>
              <p>Tags</p>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                onChange={(e) => { setSelectedTags(e) }}
                value={selectedTags}
                options={tagList}
                size="large"
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24}>
              <Form layout="vertical" className="row-col">
                {Object.keys(csvData[0] || []).map((csvItem, key) => {
                  console.log(csvData[0]);
                  return (
                    <Row style={{ marginBottom: "20px" }} gutter={50} key={key}>
                      <Col span={12}>
                        <Input
                          size="small"
                          value={csvItem + " (" + csvData[0][csvItem] + ")"}
                          disabled
                        />
                      </Col>
                      <Col span={12}>
                        <Select
                          style={{ width: "100%" }}
                          onChange={(option) =>
                            handleKeyChange(option, csvItem)
                          }
                          options={defaultKeysOption}
                          size="large"
                          placeholder="select option"
                        ></Select>
                      </Col>
                    </Row>
                  );
                })}
              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    </Context.Provider>
  );
}

export default Home;
